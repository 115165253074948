import { isMachine } from './utils.js';

function createNullActor(id) {
  return {
    id: id,
    send: function () {
      return void 0;
    },
    subscribe: function () {
      return {
        unsubscribe: function () {
          return void 0;
        }
      };
    },
    toJSON: function () {
      return {
        id: id
      };
    }
  };
}
/**
 * Creates a deferred actor that is able to be invoked given the provided
 * invocation information in its `.meta` value.
 *
 * @param invokeDefinition The meta information needed to invoke the actor.
 */


function createInvocableActor(invokeDefinition, machine) {
  var _a;

  var tempActor = createNullActor(invokeDefinition.id);
  var serviceCreator = (_a = machine.options.services) === null || _a === void 0 ? void 0 : _a[invokeDefinition.src];
  tempActor.deferred = true;

  if (isMachine(serviceCreator)) {
    tempActor.state = serviceCreator.initialState;
  }

  tempActor.meta = invokeDefinition;
  return tempActor;
}

function isActor(item) {
  try {
    return typeof item.send === 'function';
  } catch (e) {
    return false;
  }
}

export { createInvocableActor, createNullActor, isActor };